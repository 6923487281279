<template>
  <div class="authenticationError-wrap">
    <div class="header">
      <div class="title">认证失败</div>
      <div class="img-wrap">
        <img src="@/assets/imgs/authenticationError.png" alt />
      </div>
    </div>
    <div class="checkMsg">
      <div class="checkMsg-title">失败原因</div>
      <ul class="person-list">
        <li class="person-item" v-for="(item, index) in reasons" :key="index">
          <div class="label">{{ item }}</div>
        </li>
      </ul>
      <div class="checkMsg-title">核对身份信息</div>
      <ul class="person-list">
        <li class="person-item">
          <div class="label">姓名</div>
          <div class="text">{{ creditMsg.userFullName }}</div>
        </li>
        <li class="person-item">
          <div class="label">证件类型</div>
          <div class="text">身份证</div>
        </li>
        <li class="person-item">
          <div class="label">证件号</div>
          <div class="text">{{ creditMsg.userIdCardNo }}</div>
        </li>
      </ul>
    </div>
    <div class="btn1" @click="goUrl('/nameAndIdentitycard?resetAuth=1')">
      修改信息重新认证
    </div>
    <div class="btn" @click="goUrl('/auth')">尝试其他认证方式</div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        reasonsList: {
          tel: [
            '1.您填写的姓名或身份证号有误',
            '2.您的注册手机号非本人身份证办理',
          ],
          face: ['1.您填写的姓名或身份证号有误', '2.录制视频不清晰'],
          card: [
            '1.您填写的姓名或身份证号有误',
            '2.您填写的银行卡号及预留手机号有误',
          ],
        },
        reasons: [],
      }
    },
    computed: {
      ...mapState({
        creditMsg: state => state.creditMsg,
      }),
    },
    created() {
      this.reasons = this.reasonsList[this.$route.query.type]
    },
    methods: {
      goUrl(url) {
        this.$router.push(url)
      },
    },
  }
</script>
<style lang="less" scoped>
  .authenticationError-wrap {
    background: rgba(248, 249, 251, 1);
    height: 100%;
    width: 100%;
    .header {
      padding-top: 24px;
      padding-bottom: 24px;
      margin-bottom: 8px;
      background-color: #fff;
      .title {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(17, 26, 52, 1);
        line-height: 24px;
        text-align: center;
      }
      .img-wrap {
        width: 96px;
        height: 96px;
        margin: 16px auto 18px;
        img {
          width: 96px;
          height: 96px;
        }
      }
    }
    .checkMsg {
      padding: 0 16px;
      margin-bottom: 24px;
      background-color: #fff;
      .checkMsg-title {
        height: 44px;
        border-bottom: 1px solid rgba(245, 245, 245, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(60, 64, 67, 1);
        line-height: 44px;
      }
      .person-list {
        padding: 4px 0;
        .person-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 32px;
          .label {
            height: 24px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(103, 114, 131, 1);
            line-height: 24px;
          }
          .text {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(17, 26, 52, 1);
            line-height: 24px;
          }
        }
      }
    }
    .btn1 {
      width: 327px;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .btn {
      width: 327px;
      height: 44px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      border: 1px solid rgba(22, 118, 255, 1);
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(22, 118, 255, 1);
      line-height: 44px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
</style>
